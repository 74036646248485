import React from 'react';

import SignIn from '../components/SignIn';

// Example page for now only containing header
export default function SignInPage(props) {
  return (
    <React.Fragment>
      <SignIn />
    </React.Fragment>
  );
}
